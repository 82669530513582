.app {
  --primaryColor: #6554df;
  background-color: rgb(250, 248, 248);
  font-family: 'Inter', sans-serif;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.logoBar {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
  margin-top: 5vh;
  height: 10vh;
}

.logo {
  width: 250px;
  padding: 5px;
}

.fontlogo {
  font-size: x-large;
  margin: 0;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  overflow-y: auto;
  padding-top: 4vh;
  /* Create space for the header */
}

.button {
  background-color: var(--primaryColor);
  height: 2.5rem;
  width: 95vw;
  max-width: 25rem;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
}

.card {
  height: auto;
  width: 95vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
}

.inputField {
  width: 24rem;
  max-width: 94vw;
  height: 2.4rem;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding-left: 1vw;
  background-color: rgb(250, 248, 248);
  margin: 0;
}

.inputField::placeholder {
  color: rgb(160, 160, 160);
}

.inputField:focus {
  background-color: white;
  outline: 1px solid rgba(0, 0, 0, 0.3);
}